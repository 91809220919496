import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { buyUserMultitap } from '../../Apis';

const initialState = {
  userData: {},
  status: 'idle', // 'loading', 'succeeded', 'failed'
  error: null,
  userId: '',
  userRank: 0,
  userReferalList: [],
  userReferalCount: 0,
  userTask: JSON.parse(localStorage.getItem("tasks")) ? JSON.parse(localStorage.getItem("tasks")) : [],
  userTonTickets: JSON.parse(localStorage.getItem("tonTicket")) ? JSON.parse(localStorage.getItem("tonTicket")) : [],
  numberOfTickets: 0,
  totalKlinkers: 0,
  totalKlinkersPoints: 0,
  dailyActiveKlinkers: 0,
  activeKlinkers: 0,
  referrals:  0,
  klinkLeaderboard: [],
  referralCount: 0,
  taskName: '',
   totalReferralEarning : 0, 
   lifeTimePoints: 0,
   refralsInfo: [],
   userLevelData: {},
   accessToken:''
};





const userSlice = createSlice({
  name: 'user',
  initialState,
  multitapPurchase: null,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserRank: (state, action) => {
      state.userRank = action.payload;
    },
    setUserReferallist: (state, action) => {
      state.userReferalList = action.payload;
    },
    setUserReferalCount: (state, action) => {
      state.userReferalCount = action.payload;
    },
    setUserTasks: (state, action) => {
      state.userTask = action.payload;

    },
    setUserStats: (state, action) => {
      const { totalKlinkers, totalKlinkersPoints, dailyActiveKlinkers, activeKlinkers, klinkLeaderboard,totalTouches } = action.payload.community;
      const {referralCount, totalReferralEarning , lifeTimePoints, refralsInfo, referrals } = action.payload.user;
      state.totalKlinkers = totalKlinkers;
      state.totalKlinkersPoints = totalKlinkersPoints;
      state.dailyActiveKlinkers = dailyActiveKlinkers;
      state.activeKlinkers = activeKlinkers;
      state.totalTouches = totalTouches;
      state.klinkLeaderboard = klinkLeaderboard;
      state.referralCount = referralCount;
      state.totalReferralEarning = totalReferralEarning;
      state.lifeTimePoints = lifeTimePoints;
      state.refralsInfo = refralsInfo;
      state.referrals = referrals
    },
    setuserLevel: (state, action) => {
      state.userLevelData = action.payload;
    },
    setNextBootsMultiTapLevel: (state, action) => {
      state.userData.boosts.multiTap = action.payload;
    },
    setNextBootsEnergyLimitLevel: (state, action) => {
      state.userData.boosts.energyLimit = action.payload;
    },
    setNextBootsRechargingSpeedLevel: (state, action) => {
      state.userData.boosts.rechargingSpeed = action.payload;
    },
    setUserVerifiedStatus : (state,action) =>{
      state.userData.isUserVerified = action.payload;
    },
    setAccessToken:(state,action)=>{
      state.accessToken = action.payload;
    },
    setUserTonTickets : (state,action) => {
      state.userTonTickets = action.payload;
      // state.userTonTickets.numberOfTickets = action.payload;
    },
    setBoostData: (state, action) => {
      state.userData["boosts"] = action.payload;
    },

    // setBuyTonTickets: (state, action) => {
    //   state.
    // }


  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchUserRank.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(fetchUserRank.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       state.userRank = action.payload;
  //     })
  //     .addCase(fetchUserRank.rejected, (state, action) => {
  //       state.status = 'failed';
  //       state.error = action.payload;
  //     })
  //     .addCase(fetchReferralData.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(fetchReferralData.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       state.referralList = action.payload.list;
  //       state.referralCount = action.payload.count;
  //     })
  //     .addCase(fetchReferralData.rejected, (state, action) => {
  //       state.status = 'failed';
  //       state.error = action.payload;
  //     });
  // },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(buyUserMultitap.fulfilled, (state, action) => {
  //       state.multitapPurchase = action.payload;
  //     })
  //     .addCase(buyUserMultitap.rejected, (state, action) => {
  //       state.error = action.payload;
  //     });
  // },
});

export const { setUserData, setAccessToken,setUserId, setUserRank,setUserReferallist, setUserReferalCount, setUserTasks, setUserStats, setuserLevel , setNextBootsMultiTapLevel, setNextBootsEnergyLimitLevel, setNextBootsRechargingSpeedLevel,setUserVerifiedStatus, setUserTonTickets, setBoostData} = userSlice.actions;

export default userSlice.reducer;
