// // import React, { useState } from 'react';
// // import { Button, Container, Typography, Box, CardMedia, Fade, styled } from '@mui/material';
// // import Onboard1 from '../../images/Onboard1.svg';
// // import Onboard2 from '../../images/Onboard2.svg';
// // import Onboard3 from '../../images/Onboard3.svg';
// // import Onboard4 from '../../images/Onboard4.svg';
// // import ArrowRight from '../../images/Arrowright.svg';
// // import GotITArrow from '../../images/GotITArrow.svg';
// // import star_background from "../../images/Star_background.svg"

// // const onboardingSteps = [
// //   {
// //     image: Onboard1,
// //     heading: 'Welcome to Klink tap to earn',
// //     paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
// //   },
// //   {
// //     image: Onboard2,
// //     heading: 'Tap and quest to earn points',
// //     paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
// //   },
// //   {
// //     image: Onboard3,
// //     heading: 'Spend points on chosen rewards',
// //     paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
// //   },
// //   {
// //     image: Onboard4,
// //     heading: 'Payouts directly to Klink wallet',
// //     paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
// //   }
// // ];

// // const Onboarding = ({ onFinish }) => {
// //   const [step, setStep] = useState(0);
// //   const [fade, setFade] = useState(true);

// //   const nextStep = () => {
// //     setFade(false);
// //     setTimeout(() => {
// //       setStep((prevStep) => prevStep + 1);
// //       setFade(true);
// //     }, 300); // Adjust the timeout duration to match the fade duration
// //   };

// //   const prevStep = () => {
// //     setFade(false);
// //     setTimeout(() => {
// //       setStep((prevStep) => prevStep - 1);
// //       setFade(true);
// //     }, 300);
// //   };

// //   const goToStep = (index) => {
// //     setFade(false);
// //     setTimeout(() => {
// //       setStep(index);
// //       setFade(true);
// //     }, 300);
// //   };

  
// // const StyledImage = styled(Box)`
// // position: absolute;
// // z-index: -1;
// // top: 0;
// // bottom: 0;
// // right: 0;
// // left: 0;
// // width: 100%;
// // height: 100%;
// // border-radius:100%;
// // overflow:hidden;
// // animation: rotate 25s linear infinite;
// // `;

// //   const renderDots = () => (
// //     <Box display="flex" justifyContent="center" mt={2}>
// //       {onboardingSteps.map((_, index) => (
// //         <Box
// //           key={index}
// //           sx={{
// //             width: 10,
// //             height: 10,
// //             borderRadius: '50%',
// //             backgroundColor: step === index ? '#674EFF' : '#A7A5A3',
// //             margin: '0 5px',
// //             cursor: 'pointer'
// //           }}
// //           onClick={() => goToStep(index)}
// //         />
// //       ))}
// //     </Box>
// //   );

// //   return (
// //     <Container style={{ background: "transparent" }}>
// //          <StyledImage
// //       component="img"
// //       src={star_background}
// //       alt="Styled Image"
// //     />
// //       <Box sx={{ marginTop: "33.5px" }}>
// //         <Typography sx={{ fontFamily: "Inter", fontSize: "21px", fontWeight: 600, textAlign: "center", color: "#FFFFFF" }}>
// //           How Tap Bot Klink Finance works
// //         </Typography>
// //       </Box>
// //       <Box
// //         display="flex"
// //         flexDirection="column"
// //         alignItems="center"
// //         justifyContent="center"
// //         marginTop="42.5px"
// //       >
// //         <Fade in={fade} timeout={300}>
// //           <Box sx={{ width: "100%", height: "100vh"}}>
// //             <CardMedia
// //               component="img"
// //               image={onboardingSteps[step].image}
// //               alt={onboardingSteps[step].heading}
// //               style={{ width: '354.5px', height: "370px", objectFit: "contain", marginLeft: "auto" ,marginRight: "auto",willChange:"transform",transform: 'translateZ(0)' }}
// //               loading='lazy'
// //             />
// //             <Typography variant="h4" gutterBottom sx={{ textAlign: "center", color: "#FFFFFF", width: "100%", fontSize: "28px", fontFamily: "Inter", fontWeight: 700 }}>
// //               {onboardingSteps[step].heading}
// //             </Typography>
// //             <Typography variant="body1" align="center" sx={{ textAlign: "center", color: "#A7A5A3", fontSize: "16px", fontFamily: "Inter", fontWeight: 400, marginTop: "12px" }} >
// //               {onboardingSteps[step].paragraph}
// //             </Typography>
// //           </Box>
// //         </Fade>
// //         <Box mt={2} display="flex" justifyContent="space-between" alignItems="center" width="332px" position={"fixed"} bottom={'2%'} paddingLeft={'20px'} paddingRight={'20px'}>
// //           {renderDots()}
// //           <Box>
// //             {step < onboardingSteps.length - 1 ? (
// //               <Button
// //                 variant="contained"
// //                 onClick={nextStep}
// //                 style={{ background: "#674EFF", width: "72px", height: "72px", borderRadius: "72px" }}
// //               >
// //                 <img src={ArrowRight} alt="Next" />
// //               </Button>
// //             ) : (
// //               <Button
// //                 variant="contained"
// //                 style={{ background: "#674EFF", width: "165px", height: "72px", borderRadius: "72px" }}
// //                 onClick={onFinish}
// //               >
// //                 Got it <img src={GotITArrow} alt="Got It" />
// //               </Button>
// //             )}
// //           </Box>
// //         </Box>
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default Onboarding;


// import React, { useState } from 'react';
// import { Button, Container, Typography, Box, CardMedia, styled, useMediaQuery } from '@mui/material';
// import { useSwipeable } from 'react-swipeable';
// import Onboard1 from '../../images/Onboard1.svg';
// import Onboard2 from '../../images/Onboard2.svg';
// import Onboard3 from '../../images/Onboard3.svg';
// import Onboard4 from '../../images/Onboard4.svg';
// import ArrowRight from '../../images/Arrowright.svg';
// import GotITArrow from '../../images/GotITArrow.svg';
// import star_background from "../../images/Star_background.svg";
// import { Padding } from '@mui/icons-material';



// const onboardingSteps = [
//   {
//     image: Onboard1,
//     heading1: 'Welcome to Klink',
//     heading2 : 'tap to earn',
//     paragraph1: 'Your gateway to earning crypto rewards daily ',
//     paragraph2 : 'through fun and interactive actions.'
//   },
//   {
//     image: Onboard2,
//     heading1: 'Tap and quest',
//     heading2 : 'to earn points',
//     paragraph1: 'Earn points by tapping and completing',
//     paragraph2: 'easy social actions and tasks, updated daily.'
//   },
//   {
//     image: Onboard3,
//     heading1: 'Spend points on ',
//     heading2: 'chosen rewards',
//     paragraph1: 'Spend points on boosts, or convert them for',
//     paragraph2 : 'TON prize pool tickets and crypto payouts.'
//   },
//   {
//     image: Onboard4,
//     heading1: 'Payouts directly',
//     heading2: 'to Klink wallet',
//     paragraph1: 'Connect your Klink wallet to receive your',
//     paragraph2: 'rewards directly to your account.'
//   }
// ];

// const Onboarding = ({ onFinish }) => {
//   const [step, setStep] = useState(0);
//   const [swipeDirection, setSwipeDirection] = useState(null);
  

//   const isLargeScreen = useMediaQuery('(min-width: 452px)');


//   const getImageStyle = (step) => {
//     const image = onboardingSteps[step].image;
//     if (image === Onboard2) {
//       return { width: '280px', height: '306.19px', objectFit: 'cover', marginBottom: "87px" };
//     } else if (image === Onboard3) {
//       return { width: '273px', height: '294px', objectFit: 'cover', marginBottom: "89px"};
//     } else if (image === Onboard4) {
//       return { width: '370px', height: '432px', objectFit: 'inherit'};
//     }
//     else {
//       return { width: '399.5px', height: '429px', objectFit: 'initial', marginLeft: 'auto' };
//     }
//   };

//   const isCenterImage = (step) => {
//     return onboardingSteps[step].image === Onboard2 || onboardingSteps[step].image === Onboard3;
//   };

//   const nextStep = () => {
//     setSwipeDirection('right');
//     setTimeout(() => {
//       setStep((prevStep) => (prevStep + 1) % onboardingSteps.length);
//       setSwipeDirection(null);
//     }, 300);
//   };

//   const prevStep = () => {
//     setSwipeDirection('left');
//     setTimeout(() => {
//       setStep((prevStep) => (prevStep - 1 + onboardingSteps.length) % onboardingSteps.length);
//       setSwipeDirection(null);
//     }, 300);
//   };
//   const goToStep = (index) => {
//     setStep(index);
//   };

//   const handlers = useSwipeable({
//     onSwipedLeft: () => {
//       if (step < onboardingSteps.length - 1) nextStep();
//     },
//     onSwipedRight: () => {
//       if (step > 0) prevStep();
//     },
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true,
//   });

//   const StyledImage = styled(Box)`
//     position: absolute;
//     z-index: -1;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border-radius: 100%;
//     overflow: hidden;
//     animation: rotate 10s linear infinite;
//   `;

//   const renderDots = () => (
//     <Box display="flex" justifyContent="center" mt={2}>
//       {onboardingSteps.map((_, index) => (
//         <Box
//           key={index}
//           sx={{
//             width: 10,
//             height: 10,
//             borderRadius: '50%',
//             backgroundColor: step === index ? '#674EFF' : '#A7A5A3',
//             margin: '0 5px',
//             cursor: 'pointer'
//           }}
//           onClick={() => goToStep(index)}
//         />
//       ))}
//     </Box>
//   );

//   return (
//     <Box style={{ background: "transparent" }}>
//       <StyledImage component="img" src={star_background} alt="Styled Image" />
//        <Box sx={{ width: '390px' , height: '30px'}}>
//         {/* <Typography sx={{ fontFamily: "Inter", fontSize: "21px", fontWeight: 600, textAlign: "center", color: "#FFFFFF" }}>
//           How Tap Bot Klink Finance works
//         </Typography> */}
//       </Box> 
//       <Box
//         display="flex"
//         flexDirection="column"
//         alignItems="center"
//         justifyContent="center"
//         marginTop="42.5px"
//         {...handlers}
//       >
//         <Box sx={{ width: "100%", height: "100vh", position: "relative" }}>
//           <Box
//             sx={{
//               position: "absolute",
//               width: "100%",
//               height: "100%",
//               transition: "transform 0.5s ease-in-out",
//               transform: swipeDirection === 'left' ? 'translateX(200%)' : swipeDirection === 'right' ? 'translateX(-200%)' : 'translateX(0)',
//             }}
//           >
//             <Box sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: isCenterImage(step) ? 'center' : 'flex-start',
//         justifyContent: isCenterImage(step) ? 'center' : 'flex-start',
//         // height: '100vh',
//         // textAlign: isCenterImage(step) ? 'center' : 'left',
//       }}>

           
//             <CardMedia
//               component="img"
//               image={onboardingSteps[step].image}
//               alt={onboardingSteps[step].heading1}
//               // style={{ width: '399.5px', height: "429px", objectFit: "initial",willChange:"transform",transform: 'translateZ(0)' , marginLeft: "auto"}}
//               style={{ ...getImageStyle(step), willChange: 'transform', transform: 'translateZ(0)', }}
//               loading='lazy'
//             />
//              </Box>
//             <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
//             <Typography variant="h4" gutterBottom sx={{ textAlign: "center", color: "#FFFFFF", width: "100%", fontSize: "28px", fontFamily: "Inter", fontWeight: 700 , marginBottom :"0px"}}>
//               {onboardingSteps[step].heading1}
//             </Typography>
//             <Typography variant="h4" gutterBottom sx={{ textAlign: "center", color: "#FFFFFF", width: "100%", fontSize: "28px", fontFamily: "Inter", fontWeight: 700 }}>
//               {onboardingSteps[step].heading2}
//             </Typography>
//             </Box>
//             <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
//             <Typography variant="body1" align="center" sx={{ textAlign: "center", color: "#A7A5A3", fontSize: "16px", fontFamily: "Inter", fontWeight: 400, marginTop: "12px", marginBottom :"0px" }} >
//               {onboardingSteps[step].paragraph1}
//             </Typography>
//             <Typography variant="body1" align="center" sx={{ textAlign: "center", color: "#A7A5A3", fontSize: "16px", fontFamily: "Inter", fontWeight: 400, }} >
//               {onboardingSteps[step].paragraph2}
//             </Typography>
//             </Box>
//           </Box>
//         </Box>
//         <Box mt={2} display="flex" justifyContent="space-between" alignItems="center"  width={isLargeScreen ? '452px' : '332px'} position={"fixed"} bottom={'5%'} paddingLeft={'20px'} paddingRight={'20px'} >
//           {renderDots()}
//           <Box>
//             {step < onboardingSteps.length - 1 ? (
//               <Button
//                 variant="contained"
//                 onClick={nextStep}
//                 style={{ background: "#674EFF", width: "72px", height: "72px", borderRadius: "72px" }}
//               >
//                 <img src={ArrowRight} alt="Next" />
//               </Button>
//             ) : (
//               <Button
//                 variant="contained"
//                 style={{ background: "#674EFF", width: "165px", height: "72px", borderRadius: "72px" }}
//                 onClick={onFinish}
//               >
//                 Got it <img src={GotITArrow} alt="Got It" />
//               </Button>
//             )}
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Onboarding;

import React, { useEffect, useState } from 'react';
import { Button, Box, CardMedia, Typography, styled, useMediaQuery } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import Onboard1 from '../../images/Onboard1.svg';
import Onboard2 from '../../images/Onboard2.svg';
import Onboard3 from '../../images/Onboard3.svg';
import Onboard4 from '../../images/Onboard4.svg';
import ArrowRight from '../../images/Arrowright.svg';
import GotITArrow from '../../images/GotITArrow.svg';
import star_background from "../../images/Star_background.svg";

const onboardingSteps = [
  {
    image: Onboard1,
    heading1: 'Welcome to Klink',
    heading2: 'tap to earn',
    paragraph1: 'Your gateway to earning crypto rewards daily',
    paragraph2: 'through fun and interactive actions.'
  },
  {
    image: Onboard2,
    heading1: 'Tap and quest',
    heading2: 'to earn points',
    paragraph1: 'Earn points by tapping and completing',
    paragraph2: 'easy social actions and tasks, updated daily.'
  },
  {
    image: Onboard3,
    heading1: 'Spend points on',
    heading2: 'chosen rewards',
    paragraph1: 'Spend points on boosts, or convert them for',
    paragraph2: 'TON prize pool tickets and crypto payouts.'
  },
  {
    image: Onboard4,
    heading1: 'Payouts directly',
    heading2: 'to Klink wallet',
    paragraph1: 'Connect your Klink wallet to receive your',
    paragraph2: 'rewards directly to your account.'
  }
];

const preloadImages = (imageArray) => {
  imageArray.forEach((step) => {
    const img = new Image();
    img.src = step.image;
  });
};


const Onboarding = ({ onFinish }) => {
  const [step, setStep] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    preloadImages(onboardingSteps);
    setLoading(false);
  }, []);

  const isLargeScreen = useMediaQuery('(min-width: 452px)');

  const getImageStyle = (step) => {
    const image = onboardingSteps[step].image;
    if (image === Onboard2) {
      return { width: '280px', height: '306.19px', objectFit: 'cover', marginBottom: "87px" };
    } else if (image === Onboard3) {
      return { width: '273px', height: '294px', objectFit: 'cover', marginBottom: "89px" };
    } else if (image === Onboard4) {
      return { width: '370px', height: '432px', objectFit: 'inherit', marginLeft: "-20px"};
    } else {
      return { width: '399.5px', height: '429px', objectFit: 'initial', marginLeft: 'auto' };
    }
  };

  const nextStep = () => {
    setSwipeDirection('right');
    setTimeout(() => {
      setStep((prevStep) => (prevStep + 1) % onboardingSteps.length);
      setSwipeDirection(null);
    }, 300);
  };

  const prevStep = () => {
    setSwipeDirection('left');
    setTimeout(() => {
      setStep((prevStep) => (prevStep - 1 + onboardingSteps.length) % onboardingSteps.length);
      setSwipeDirection(null);
    }, 300);
  };

  const goToStep = (index) => {
    setSwipeDirection(index > step ? 'right' : 'left');
    setTimeout(() => {
      setStep(index);
      setSwipeDirection(null);
    }, 300);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (step < onboardingSteps.length - 1) nextStep();
    },
    onSwipedRight: () => {
      if (step > 0) prevStep();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const StyledImage = styled(Box)`
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    animation: rotate 10s linear infinite;
  `;

 

  const renderDots = () => (
    <Box display="flex" justifyContent="center" mt={2}>
      {onboardingSteps.map((_, index) => (
        <Box
          key={index}
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: step === index ? '#674EFF' : '#A7A5A3',
            margin: '0 5px',
            cursor: 'pointer'
          }}
          onClick={() => goToStep(index)}
        />
      ))}
    </Box>
  );
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h4" color="#FFFFFF">Loading...</Typography>
      </Box>
    );
  }
  return (
    <Box style={{ background: "transparent" }}>
      <StyledImage component="img" src={star_background} alt="Styled Image" />
      <Box sx={{ width: '390px', }} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginTop="42.5px"
        {...handlers}
      >
        <Box sx={{ width: "100%", height: "100vh", position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              transition: "transform 0.5s ease-in-out",
              transform: swipeDirection === 'left' ? 'translateX(-200%)' : swipeDirection === 'right' ? 'translateX(200%)' : 'translateX(0)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <CardMedia
                component="img"
                image={onboardingSteps[step].image}
                alt={onboardingSteps[step].heading1}
                style={{ ...getImageStyle(step), willChange: 'transform', transform: 'translateZ(0)' }}
                loading='lazy'
              />
              <Typography variant="h4" gutterBottom sx={{ textAlign: "center", color: "#FFFFFF", width: "100%", fontSize: "28px", fontFamily: "Inter", fontWeight: 700 , marginBottom: "0px"}}>
                {onboardingSteps[step].heading1}
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ textAlign: "center", color: "#FFFFFF", width: "100%", fontSize: "28px", fontFamily: "Inter", fontWeight: 700 }}>
                {onboardingSteps[step].heading2}
              </Typography>
              <Typography variant="body1" align="center" sx={{ textAlign: "center", color: "#A7A5A3", fontSize: "16px", fontFamily: "Inter", fontWeight: 400,}}>
                {onboardingSteps[step].paragraph1}
              </Typography>
              <Typography variant="body1" align="center" sx={{ textAlign: "center", color: "#A7A5A3", fontSize: "16px", fontFamily: "Inter", fontWeight: 400 }}>
                {onboardingSteps[step].paragraph2}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center" width={isLargeScreen ? '452px' : '332px'} position={"fixed"} bottom={'5%'} paddingLeft={'20px'} paddingRight={'20px'}>
          {renderDots()}
          <Box>
            {step < onboardingSteps.length - 1 ? (
              <Button
                variant="contained"
                onClick={nextStep}
                style={{ background: "#674EFF", width: "72px", height: "72px", borderRadius: "72px" }}
              >
                <img src={ArrowRight} alt="Next" />
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ background: "#674EFF", width: "165px",fontWeight: 873,fontFamily: "Inter", height: "72px", borderRadius: "72px" }}
                onClick={onFinish}
              >
                Got it <img src={GotITArrow} alt="Got It" />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Onboarding;

