import React from 'react';
import { Box, keyframes, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50%);
  }
`;

const Dot = styled(Box)(({ theme, delay }) => ({
  borderRadius: '50%',
  border: `2px solid #674EFF`,
  opacity: 0.9,
  height: "2px",
  width: "2px",
  margin: "1px",
  backgroundColor: '#674EFF',
  animation: `${bounce} 0.6s infinite`,
  animationDelay: delay,
}));

const SmallLoader = () => {
  const theme = useTheme();
  const numberOfDots = 5; // Number of dots
  const dots = [];

  for (let i = 0; i < numberOfDots; i++) {
    dots.push(<Dot key={i} delay={`-${i * 0.1}s`} />);
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" m={2}>
      {dots}
    </Box>
  );
};

export default SmallLoader;
