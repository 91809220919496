import { Box, Grid } from "@mui/material";
import React from "react";
import Gift from "../images/Gift.svg";
import { Typography } from "@mui/material";
import HashTagCoin2 from "../images/hashtagCoin2.svg";


const Modal = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",

        // backgroundColor:"black"
      }}
    >
      <Box
        sx={{
          minWidth: "343px",
          width: "90%",
          minHeight: "513px",
          height:"73%",
          marginTop: "115px",
          backgroundImage: "linear-gradient(180deg, #1D765B , #203E84 )",
          borderRadius: "20px",
          display: "flex",
          //   justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap:"26px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            marginTop:"26px"
          }}
        >
          <img src={Gift} alt="Gift" />
          <Typography
            comp
            sx={{
              fontSize: "24px",
              fontWeight: 800,
              color: "#F4FDFF",
            }}
          >
            Daily Vocal Warmup
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#F4FDFF",
            }}
          >
            Daily Earning Rewards
          </Typography>
        </Box>

        <Grid
        container
         rowSpacing={1}
         columnSpacing={{md:6,sm:4}}
         sx={{
            gap:"7px",
            alignContent:"center",
            alignItems:"center",
            justifyContent:"center",
            paddingX:"3px"
         }}
        
        >
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #66B6FF",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#F4FDFF",
                textAlign: "center",
              }}
            >
              Day 1
            </Typography>

            <Box
              sx={{
                backgroundImage: "linear-gradient(180deg, #3772FF , #080708 )",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingX: "20px",
                paddingY: "9px",
                borderRadius: "10px",
              }}
            >
              <img src={HashTagCoin2} alt="hashTagCoin" />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#8EEAFF",
                }}
              >
                1k pts
              </Typography>
            </Box>
          </Box>
        </Grid>


        <Box
        sx={{
            border:"2px solid #1D765B",
            paddingX:"17px",
            paddingY:"13px",
            borderRadius:"10px"
        }}
        >
            <Typography
            sx={{
                color:'#F4FDFF',
                fontWeight:600
            }}
            >
            Check back in  10:22:06
            </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Modal;
