import { setCurrentEnergy, setMaxEnergy, setTapPoints, setUserTotalTaps } from "../store/slices/miningInfoSlice";
import { setCurrentPoint, setCurrentTokenCount, setUserPoint,  reduceCurrentPoint,reduceUserPoints, IncreaseUserPoints, IncreaseCurrentPoint } from "../store/slices/pointSlice";
import {
  setUserData,
  setUserId,
  setUserRank,
  setUserTasks,
  setUserStats,
  setuserLevel,
  setNextBootsMultiTapLevel,
  setNextBootsRechargingSpeedLevel,
  setNextBootsEnergyLimitLevel,
  setUserVerifiedStatus,
  setUserTonTickets,
  setAccessToken,
  setBoostData,
 
} from "../store/slices/userSlice";
import axios from "axios";

export function init() {
  return async (dispatch) => {
    const search = window.Telegram.WebApp.initData;
    let data = null;
    if (search) {
      const converted = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      data = JSON.parse(converted.user);
    }
    if (data) {
      dispatch(setUserId(data.id));
      // setIsTelegramMiniApp(true)
    }
  };
}

export function setData(userId, setLoading,accessToken) {
  return async (dispatch) => {
    try {
      const startTime = performance.now(); // Record the start time
      if (!userId) {
        // console.error("user id not found");
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}?isAuth=true`,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      const endTime = performance.now();
      const responseTime = endTime - startTime; // Calculate the response time
      console.log('API Response:', response.data);
      console.log(`API Response Time: ${responseTime.toFixed(2)}ms`);
      // console.log("userdata", response);
      setLoading(false);
      dispatch(setAccessToken(response?.data?.data?.token))
      dispatch(setUserPoint(response?.data?.data?.user?.points))
      dispatch(setCurrentTokenCount(response?.data?.data?.user?.points));
      dispatch(setUserData(response?.data?.data?.user));
      dispatch(setTapPoints(response?.data?.data?.pointsPerTap));
      dispatch(setMaxEnergy(response?.data?.data?.user?.maxEnergy));
      dispatch(setCurrentEnergy(response?.data?.data?.user?.currentEnergy));
// console.log("curent energy ", response?.data?.data?.user?.currentEnergy);
      
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}



export function SetBoostData(userId, accessToken) {
  return async (dispatch) => {
    try {
      if (!userId || !accessToken) {
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/boots/boostHistory/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("SetBoostData", response);
      dispatch(setBoostData(response?.data?.user?.boosts));
      console.log("userBoost", response?.data?.user?.boosts);
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}

export function setLevelsData(userId,accessToken) {
  return async (dispatch) => {
    try {
      if (!userId || !accessToken) {
        // console.error("user id not found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/league/user/${userId}`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log("setLevelsData", response.data);
      dispatch(setuserLevel(response?.data?.data.leagues))
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}





export function getUserRank(userId,accessToken) {
  return async (dispatch) => {
    try {
      if (!userId || !accessToken) {
        // console.error("user id not found");
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/ranks/${userId}`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
      dispatch(setUserRank(response.data.rank));
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}



export function getUserTask(userId,accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      // console.log("user id not found");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/task/getUserTasks/${userId}`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const data = response.data;
      // window.location.reload();
      console.log("data", data);

      // const  user = response.data.user
      // console.log("user", user);
      // if(JSON.parse(localStorage.getItem('tasks'))?.length===data?.tasks?.length) {
      //   return
      // };
      dispatch(setUserTasks(data.tasks));
      // dispatch(setUserPoint(user.points));
      // console.log("setUserPoint",user.points);
      // dispatch(setCurrentPoint(user.points));
      // console.log("setCurrentPoint",user.points);
      
      // localStorage.setItem("tasks", JSON.stringify(data.tasks));

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
}

export function callTapApi(currentPoints, userPoint, userId, tapPoints, totalTaps, currentEnergy,accessToken) {
  // if (clickCounter.current === 0) return;
  return async (dispatch) => {
    // console.log("cuprrent points", currentPoints);
    // console.log("tapPoints", tapPoints);
    // console.log("totalTaps", totalTaps);
    // console.log("userPoint", userPoint);


    try {
      if (!userId || !accessToken) {
        // console.log("userId Not Found");
        return;
      }
      let pointsToAdd = (+currentPoints - +userPoint);
      if (pointsToAdd < 0) pointsToAdd = 0; 
      // console.log("pointsToAdd", pointsToAdd);
      const data = {
        points: pointsToAdd + tapPoints,
        totalTaps: totalTaps,
        currentEnergy: currentEnergy
      }
      // console.log("data-----------?",data);
      if (pointsToAdd < 0) return;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}/add-point`, data,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response) {


        dispatch(setUserPoint(response?.data?.data?.points))
        dispatch(setCurrentEnergy(response?.data?.data?.currentEnergy))
        console.log("setCurrentEnergy",response?.data?.data?.currentEnergy );
        dispatch(setUserTotalTaps(0));
        const lostPoint = currentPoints - response?.data?.data?.points;
        if (lostPoint > 0) dispatch(setCurrentTokenCount(lostPoint))
      }
    } catch (error) {
      console.error('Error updating score:', error);
      if (error.response && error.response.status === 404) {
        console.error('Endpoint not found (404)');
      }
    } finally {
      // clickCounter.current = 0;
    }
  }
};




export function userCompleteTask(id, setCompletedTasks, userId, setShowLoader,accessToken, taskPoints,setClaimLoading,setSuccess) {
  return async (dispatch) => {
    const data = {
      taskId: id,
    };
    // console.log("user task id ", data);
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/task/execute/${userId}`,
        data,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log("completed task", response.data);

      if (response.status === 200 || response.status === 201) {
        setShowLoader(false)
        setCompletedTasks((prevCompletedTasks) => [...prevCompletedTasks, id]);
        dispatch(getUserTask(userId,accessToken))
        dispatch(IncreaseUserPoints(taskPoints))
        dispatch(IncreaseCurrentPoint(taskPoints))
        
       await sleep(1000)
       setShowLoader(false);
        setClaimLoading(true);
        setSuccess(true);
      }

    } catch (error) {
      console.error("Error completing the task:", error);
      // setShowLoader(false);
      setClaimLoading(true);
      setSuccess(true);
      setShowLoader(false);
    }
  }
}


export function getUserStats(userId, setloading,accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      // console.log("user id not found");
      return;
    }
    try {
      setloading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${userId}/stats`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const data = response.data.data;
      setloading(false)
      const useractivity = response.data.data.user
      // console.log("data of stats", response.data.data);
      // console.log("data of user ", response.data.data.user);
      dispatch(setUserStats(data));
      // dispatch(setUserStats(data.klinkLeaderboard));



    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}



function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function BuyUserMultitap(userId,accessToken,navigate,setResultPopUp,setSuccess,setModalLoadder,setOpenModal,setPopUpMessage,setTafficLight) {
  
  return async (dispatch) => {
    // console.log("userID", userId);
    const data = {
      userId: userId,
    };
    // console.log("user task id ", data );
    try {
      setModalLoadder(true);
      setOpenModal(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/boots/buyMultiTap`,
        data,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // console.log("userBuyMultitap", response?.data?.user?.points);
      // console.log("userBuyMultitap", response?.data?.user?.boosts?.multiTap?.currentValue);
      // dispatch(setUserPoint(response?.data?.user?.points))
      // dispatch(setCurrentPoint(response?.data?.user?.points));
      // dispatch(setTapPoints(response?.data?.user?.boosts?.multiTap?.currentValue));
      // dispatch(setUserData(response?.data?.user))
      
      if (response) {
        const user = response.data.user
        console.log("user",user );
        const multiTapValue = user.boosts.multiTap.currentValue;
        console.log("multiTapValue", multiTapValue);

        dispatch(setUserPoint(user.points));
        dispatch(setCurrentPoint(user.points));
        dispatch(setTapPoints(multiTapValue));
        dispatch(setNextBootsMultiTapLevel(user?.boosts?.multiTap));
        setModalLoadder(false);
        setResultPopUp(true);
        setSuccess(true);
        setOpenModal(false);
        setPopUpMessage("Your points have been successfully redeemed")

        
      
        // await sleep(2000);
        // navigate("/")
      }

    } catch (error) {
      console.error("Error completing the task:", error?.response?.status);
      
     if(error?.response?.status==409){
      setOpenModal(false);
      setModalLoadder(false);
      setTafficLight(true);
     }else{
      setPopUpMessage('Something went wrong,Please try again...')
      setModalLoadder(false);
      setResultPopUp(true);
      setOpenModal(false);
      setSuccess(false);
     }

    }
  }
}

export function emailVerification(email,userId,setLoading,navigate,setIsOpen,setBoxMessage,setIsMessageBoxOpen,accessToken,setAccounConnectedModal){
  return async (dispatch)=>{
    try{  
      if(email.length >=30) {
        setBoxMessage("please enter vaild email");
        setBoxMessage(true);
        return
      }
      setLoading(true);
      setIsOpen(true);
      if(!email || !userId || !accessToken) console.log("Data not found");

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/verifyKlinkUser`,{email,userId},{
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });  
    dispatch(setUserVerifiedStatus(true));
    setAccounConnectedModal(true)
    // setBoxMessage("Email verification Done");
    // setIsOpen(false);
    // setLoading(false);
    // setIsMessageBoxOpen(true);
    }catch(error){    
      console.error("Email verification failed");
      setBoxMessage("Email verification failed")
      setLoading(false);
      setIsOpen(false);
      setIsMessageBoxOpen(true);
    }
  }

}

export function onBoardUser(userId,accessToken){
  return async (dispatch)=>{
    try{
      if(!userId || !accessToken) {
        // console.error("userId Not Found");
        return;
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/isVisited`,{userId},{
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      window.location.reload()
    }catch(error){
      console.log(error);
    }
  }

}







export function BuyUserEnergyLimit(userId,accessToken,navigate,setResultPopUp,setSuccess,setModalLoadder,setOpenModal,setPopUpMessage,setTafficLight) {
  
  return async (dispatch) => {
    const data = {
      userId: userId,
    };
    try {
      setModalLoadder(true);
      setOpenModal(true);
    
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/boots/buyEnergyLimit`,
        data,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // console.log("userBuyMultitap", response?.data?.user?.points);
      // console.log("userBuyMultitap", response?.data?.user?.boosts?.multiTap?.currentValue);
      // dispatch(setUserPoint(response?.data?.user?.points))
      // dispatch(setCurrentPoint(response?.data?.user?.points));
      // dispatch(setTapPoints(response?.data?.user?.boosts?.multiTap?.currentValue));
      // dispatch(setUserData(response?.data?.user))
      
      if (response) {
        const user = response.data.user;
        const EnergyLimitValue = user.boosts.energyLimit.currentValue;

        dispatch(setUserPoint(user.points));
        dispatch(setCurrentPoint(user.points));
        dispatch(setNextBootsEnergyLimitLevel(user?.boosts?.energyLimit));
        setOpenModal(false); 
        setModalLoadder(false);
        setResultPopUp(true);
        setSuccess(true);
        setPopUpMessage('Your points have been successfully redeemed')
  
        
      }

      console.log("energylimit called", response);
  
      
     

    } catch (error) {
      console.error("Error completing the task:", error);
      if(error?.response?.status==409){
        setOpenModal(false);
        setModalLoadder(false);
        setTafficLight(true);
       }else{
        setPopUpMessage('Something went wrong,Please try again...')
        setModalLoadder(false);
        setResultPopUp(true);
        setOpenModal(false);
        setSuccess(false);
       }
    }
  }
}





export function BuyUserRechargeSpeed(userId,accessToken,navigate,setResultPopUp,setSuccess,setModalLoadder,setOpenModal,setPopUpMessage,setTafficLight) {
  
  return async (dispatch) => {
    // console.log("userID", userId);
    const data = {
      userId: userId,
    };
    // console.log("user task id ", data );
    try {
      setModalLoadder(true);
      setOpenModal(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/boots/buyRechargingSpeed`,
        data,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // console.log("userBuyMultitap", response?.data?.user?.points);
      // console.log("userBuyMultitap", response?.data?.user?.boosts?.multiTap?.currentValue);
      // dispatch(setUserPoint(response?.data?.user?.points))
      // dispatch(setCurrentPoint(response?.data?.user?.points));
      // dispatch(setTapPoints(response?.data?.user?.boosts?.multiTap?.currentValue));
      // dispatch(setUserData(response?.data?.user))
      
      if (response) {
        const user = response.data.user;
        const RechargeSpeedValue = user.boosts.rechargingSpeed.currentValue;

        dispatch(setUserPoint(user.points));
        dispatch(setCurrentPoint(user.points));
        // dispatch(setTapPoints(RechargeSpeedValue));
        dispatch(setNextBootsRechargingSpeedLevel(user?.boosts?.rechargingSpeed));
        // dispatch(setUserData(user));
        setOpenModal(false); 
        setModalLoadder(false);
        setResultPopUp(true);
        setSuccess(true);
        setPopUpMessage('Your points have been successfully redeemed')
  
      }

      console.log("RechargeSpeed", response);
  
      
     

    } catch (error) {
      console.error("Error completing the task:", error);
      if(error?.response?.status==409){
        setOpenModal(false);
        setModalLoadder(false);
        setTafficLight(true);
       }else{
        setPopUpMessage('Something went wrong,Please try again...')
        setModalLoadder(false);
        setResultPopUp(true);
        setOpenModal(false);
        setSuccess(false);
       }
    
    }
  }
}


export function getKlinkRegistrationInfo(userId,setClaim,setTaskKlinkId,accessToken,setClaimLoading){
   return async (dispatch)=>{
    try{
      setClaimLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/task/getUserRegesterTasks/${userId}`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        setTaskKlinkId(response?.data?.tasks?.task?._id);
        setClaim(response?.data?.tasks?.task?.completed);
        setClaimLoading(false);
    }catch(error){
      console.log("Error While geting task data",error)
      setClaimLoading(false);
    }
   }
}

export function claimKlinkRegistrationPointInfo(userId,taskId,accessToken,setClaimLoading){
  return async (dispatch)=>{
   try{
    setClaimLoading(true);
    if(!taskId || !accessToken){
      console.log("TaskId not Found");
      return;
    }
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/task/claimRegBonum/${userId}`,{taskId},{
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
   window.location.reload();

   }catch(error){
    setClaimLoading(false);
     console.log(error);
   }
  }
}


export function getUserTonTickets(userId,accessToken) {
  return async (dispatch) => {
    if (!userId) {
      // console.log("user id not found");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/allTickets`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      const data = response.data;
      // if(JSON.parse(localStorage.getItem('tonTicket'))?.length===data?.length) {
      //   return
      // };
      dispatch(setUserTonTickets(data));
      // localStorage.setItem("tonTicket",JSON.stringify(data));

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
}

export function BuyUserTonTickets(userId, id,accessToken,navigate,setResultPopUp,setSuccess,setModalLoadder,setOpenModal,setPopUpMessage) {
  return async (dispatch) => {
    const data = {
      ticketID: id,
      userTgId: userId,
    };

  

    try {
      setOpenModal(true); 
      setModalLoadder(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/buyTickets`, data,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Check if response exists and the status is 201
    
        const result = response.data;
        const user = result?.user;
   dispatch(reduceCurrentPoint(result?.ticketHistory?.ticketDetails?.ticketCost))
   dispatch(reduceUserPoints(result?.ticketHistory?.ticketDetails?.ticketCost))
        // dispatch(setUserPoint(user?.points));
        // dispatch(setCurrentPoint(user?.points));
          
      setOpenModal(false); 
      setModalLoadder(false);
      setResultPopUp(true);
      setSuccess(true);
      
      
    } catch (error) {
      console.log("error",error?.response?.data?.message)
      setPopUpMessage(error?.response?.data?.message)
      setOpenModal(false); 
      setModalLoadder(false);
      setResultPopUp(true);
      setSuccess(false);
    }
  }
}


export  function genrateAuthToken(userId,setAccessToken){
  return async (dispatch)=>{
    try{
      if(!userId){
        // console.error("userId not found");
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/token/${userId}`);
      dispatch(setAccessToken(response?.data?.token));
    }catch(err){

    }
  }
}
