import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  miningInfo: { tapPoints: 1, currentEnergy: 0, maxEnergyLimit: 0 },
  totalTaps:0,
};

const mineInfoSlice = createSlice({
  name: "mineInfo",
  initialState,
  reducers: {
    setTapPoints: (state, action) => {
      state.miningInfo["tapPoints"] = action.payload;
    },
    setCurrentEnergy: (state, action) => {
      if(!action.payload) return;
      state.miningInfo["currentEnergy"] = action.payload;
    },
    setMaxEnergy: (state, action) => {
      if(!action.payload) return;
      state.miningInfo["maxEnergyLimit"] = action.payload;
    },
    setUserTotalTaps : (state,action) =>{
        state.totalTaps = action.payload;
    },
    reduceCurrentEnergy : (state,action) =>{ 
      if( state.miningInfo["currentEnergy"] - action.payload <0) return;
     state.miningInfo["currentEnergy"] = state.miningInfo["currentEnergy"] - action.payload;
    },
    restoreEnergy : (state,action) =>{
      if(!action.payload) return;
        state.miningInfo["currentEnergy"] = state.miningInfo["currentEnergy"] + action.payload;
    },
    increaseUserTapCount: (state,action)=>{
        state.totalTaps = state.totalTaps+1;
    }
  
  },
});

export const { setTapPoints, setCurrentEnergy, setMaxEnergy ,setUserTotalTaps,reduceCurrentEnergy,restoreEnergy,increaseUserTapCount} =
  mineInfoSlice.actions;

export default mineInfoSlice.reducer;
