import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 12,
   border:"3px solid #000",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#000',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 12,
    background: '#9583FF',
    outline:"3px solid #000"
  },
}));

const MyProgress = ({ value, max }) => {
  // Calculate the percentage of the progress
  const percentage = (value / max) * 100;
  

  return (
    <Box display="flex" alignItems="center"  marginX="auto" width="90vw">
      <Box width="100%" mr={1}  sx={{
        border:"1px solid #9583FF",
        borderRadius:12,
        boxShadow: '1px 1px 20px  10px #9582ff0d,   1px -1px 20px  10px #9582ff0d,-1px 1px 20px  10px #9582ff0d, -1px -1px 20px  10px #9582ff0d' ,
        boxSizing:"border-box"
      }}>
        <BorderLinearProgress variant="determinate" value={percentage ? percentage : 4}  />
      </Box>
      {/* <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          normalizedValue,
        )}%`}</Typography>
      </Box> */}
    </Box>
  );
};


export default MyProgress;