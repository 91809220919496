import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: "100px",
   border:"4px solid #000",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#000',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: 'linear-gradient( 50deg ,#FBC44D,#FBC44D,#FFE4A9)'
  },
}));

const MyProgress = ({ value, max }) => {
  // Calculate the percentage of the progress
  const persantage = (value / max) * 100;
  

  return (
    <Box display="flex" alignItems="center" justifyContent={"center"} position={"relative"}  marginX="auto" width="90vw" marginTop={"15px"}>
      <Box width="100%" mr={1}  sx={{
        outline:"2px solid #fbc44d",
        borderRadius:8,

      }}>
        <BorderLinearProgress variant="determinate" value={persantage}  />
      </Box>
      <Box 
      sx={{
        position:"absolute",
        width:"100%",
        height:"50px",
        backgroundColor:"#fbc44d78",
        zIndex:"-1",
        left:"-4px",
        filter: 'blur(20.6px)',
      }}
      
      >
       
      </Box>
    </Box>
  );
};


export default MyProgress;