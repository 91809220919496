import React from 'react';
import { styled, keyframes } from '@mui/system';
import {Box} from "@mui/material";

const ThreeSectionLodder = () => {
    const rotateAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `;
  
  const LoaderContainer = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  }));
  
  const LoaderSection = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #9583FF',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,

  
  }));
  const LoaderSection2 = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid white',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));
  const LoaderSection3 = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid white',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));
  
  const LoaderSectionDelayed1 = styled(LoaderSection2)(({ theme }) => ({
    animationDelay: '0.33s',
  }));
  
  const LoaderSectionDelayed2 = styled(LoaderSection3)(({ theme }) => ({
    animationDelay: '0.66s',
  }));
  

  return (
    <LoaderContainer>
    <LoaderSection />
    <LoaderSectionDelayed1 />
    <LoaderSectionDelayed2 />
  </LoaderContainer>
  )
}

export default ThreeSectionLodder