import React, { useState } from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Unsccuess from "../images/Unsuccess.svg";
import Sccuess from '../images/Confirm.svg'

const SuccessFailPopUp = ({setResultPopUp,success,navigate,customMessage }) => {
  const FadeContainer = styled(Box)`
    animation: fadeIn 0.5s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
   `;
  const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
  const slideDown = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;
const handelClick = () =>{
  if(success) navigate('/');
  if(!success) setResultPopUp(false);
}
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        background: "#1B1529FA",
        height: "393px",
        zIndex: 1000000,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "25px",
        animation: `${slideUp} 200ms ease-out forwards`,
        borderRadius: "25px",
        boxSizing:"border-box"
      }}
    >
      <>
        <IconButton
          onClick={()=>setResultPopUp(false)}
          sx={{
            position: "absolute",
            top: "12px",
            right: "12px",
            color: "#fff",
          }}
        >
          <CloseIcon width={16} height={16} />
        </IconButton>
       {!success &&  <img src={ Unsccuess  } alt="icon" width={120} height={120} />}
       {success && <img src={ Sccuess  } alt="icon" width={120} height={120} /> }
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            
            height:"101px"
          }}
        >
          <Typography
            component="p"
            sx={{
              fontFamily: "Inter",
              fontWeight: 800,
              fontSize: "32px",
              lineHeight: "38.4px",
              color: "#fff",
              letterSpacing:"-1px"
            }}
          >
          {success ? "Success" :" Failure"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16.94px",
              color: "#fff",
              marginTop:"12px"
            }}
          >
            {customMessage}
          </Typography>
        </Box>

        <button
          style={{
            width: "350px",
            background: "#674EFF",
            height:"55px",
            outline: "None",
            border: "None",
            borderRadius: "12px",
            marginX:'auto'
          }}
          onClick={handelClick}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 873,
              fontSize: "18px",
              lineHeight: "23.4px",
              color: "#fff",
              textAlign:"center"
            }}
          >
          {success ? "Done":"Try again"}
          </Typography>
        </button>
      </>
    </Box>
  );
};

export default SuccessFailPopUp;
