import logo from "./logo.svg";
import CoinApp from "./pages/Tap";
import { BottomNavigation, Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import env from "react-dotenv";
import "./App.css";
import "./css/customStyle.css";
import './index.css'
import GoogleTrans from "./components/GoogleTranslator/Translator";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Mates from "./pages/Ref";
import BottomNavbar from './components/BottomNavbar/BottomNavbar'
import Earn from "./pages/Task";
import Boost from "./pages/Boost";
import Stats from "./pages/Stats";
import Modal from "./components/DailyReward"
import { useDispatch } from "react-redux";
import { SetBoostData, genrateAuthToken, getUserStats, getUserTask, getUserTonTickets, init, onBoardUser, setData } from "./Apis";
import klinkQR from './images/KlinkQR.svg'
import star_background from "./images/Star_background.svg"
import Levels from "./pages/Levels";
import { useSelector } from 'react-redux';
import Friends from "./pages/Ref";
import Onboarding from "./components/OnboardScreens/Onboard";
import ThreeSectionLodder from "./components/ThreeSectionLodder"
import Register from "./pages/Register";
import KlinkFLogo from './images/KlinkFLogo.svg'
import { setAccessToken } from "./store/slices/userSlice";
import { restoreEnergy, setCurrentEnergy } from "./store/slices/miningInfoSlice";

const theme = createTheme();
const telApp = window.Telegram.WebApp;
const isPhone = window.innerWidth < 600;

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /android|iphone|ipad|ipod|opera mini|iemobile|wpdesktop/i.test(userAgent.toLowerCase());
    setIsMobile(isMobile);
  }, []);

  return isMobile;
};

const useIsTelegramWebApp = () => {
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);

  useEffect(() => {
    const isTelegramWebApp = /TelegramDesktop|TelegramWeb/i.test(navigator.userAgent);
    setIsTelegramWebApp(isTelegramWebApp);
  }, []);

  return isTelegramWebApp;
};

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const userId = useSelector((state) => state.user.userId);
  const userData = useSelector((state) => state.user.userData);
  const accessToken = useSelector((state)=>state?.user?.accessToken);
  const userMiningInfo = useSelector((state) => state?.miningInfo?.miningInfo)
  const userCurrentEnergy = useSelector((state) => state?.miningInfo?.miningInfo.currentEnergy)
  

  // const  boost = useSelector((state) =>  state?.user?.boosts)
  useEffect(() => {
    dispatch(init())
    
  }, [])


  
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
  const isMobile = useIsMobile();
  const isTelegramWebApp = useIsTelegramWebApp();
  const isTelegramMiniApp = true; // Replace this with your actual logic to detect Telegram Mini App

  const handleOnboardingFinish = () => {
    setIsOnboardingCompleted(true);
    dispatch(onBoardUser(userId,accessToken));
  };
 

  const [startTime] = useState(performance.now());

  useEffect(() => {
    const renderTime = performance.now() - startTime;
    console.log(`App render time: ${renderTime.toFixed(2)}ms`);
  }, [startTime]);
  
  useEffect(() => {
    const interval = setInterval(async () => {
  
      if (userCurrentEnergy >= userData?.boosts?.energyLimit?.currentValue) {
        if( userData?.boosts?.energyLimit?.currentValue >= 1){
          return
        }
        dispatch(setCurrentEnergy(userData?.boosts?.energyLimit?.currentValue));
        clearInterval(interval);
      } else {
        dispatch(restoreEnergy(userData?.boosts?.rechargingSpeed?.currentValue));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [userCurrentEnergy,userData?.boosts?.energyLimit?.currentValue ]);


  useEffect(() => {
    if (telApp) {
      telApp.expand();
    }
  }, []);
 useEffect(() => {
    dispatch(setData(userId, setLoading));  
  }, [userId]);
  useEffect(() => {
    dispatch(SetBoostData(userId, accessToken));
    dispatch(getUserTask(userId, accessToken));
    dispatch(getUserTonTickets(userId,accessToken));
    dispatch(getUserStats(userId,setLoading,accessToken));
  }, [accessToken]);
  return (
    <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
      {loading ? (
        <Box sx={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
          <ThreeSectionLodder />
        </Box>
      ) : (
        <>
          {!isMobile && isTelegramMiniApp && !isTelegramWebApp ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: 'transparent', height: '100vh', zIndex: 'auto' }}>
               
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img src={KlinkFLogo}  width={92.25} height={24}/>
                <h1 style={{ color: 'white', fontFamily: 'Inter', fontWeight: 800, textAlign: 'center' }}>
                  Leave the desktop. <span style={{ color: 'white', fontFamily: 'Inter', fontWeight: 800, display: 'inline-block' }}>Mobile gaming rocks!</span>
                </h1>
              </div>
              <div style={{ width: '250px', height: '250px', borderRadius: '35px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={star_background} style={{ position: "absolute", zIndex: -1, top: 0, bottom: 0, right: 0, left: 0, width: "100%", height: "100%", }} />
                <img src={klinkQR} width={250} height={250} style={{ borderRadius: '35px' }} alt="QR Code" />
              </div>
            </div>
          ) : (
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Routes>
                  {!userData?.isVisited ? (
                    <Route path="/" element={<Onboarding onFinish={handleOnboardingFinish} />} />
                  ) : (
                    <>
                      <Route path="/" exact element={<CoinApp />} />
                      <Route path="/friends" element={<Friends />} />
                      <Route path="/earn" element={<Earn />} />
                      <Route path="/boost" element={<Boost />} />
                      <Route path="/stats" element={<Stats />} />
                      <Route path="/daily-reward" element={<Modal />} />
                      <Route path="/user-level-details" element={<Levels />} />
                      <Route path="/register" element={<Register />} />
                    </>
                  )}
                </Routes>
                {userData?.isVisited && <BottomNavbar />} {/* Render BottomNavbar only if onboarding is completed */}
              </BrowserRouter>
            </ThemeProvider>
          )}
        </>
      )}
      <Box
        sx={{
          position: 'absolute',
          width: '680px',
          height: '250px',
          background: '#9b78ed7e',
          borderRadius: '50%',
          transform: 'rotate(-25deg)',
          left: '-138px',
          top: '60px',
          filter: 'blur(80.6px)',
          zIndex: '-10'
        }} 
      />
     
    </div>
  );
}

export default App;
