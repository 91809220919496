import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import tap from '../../images/KlinkCoin.svg';
import Task from '../../images/Task.svg';
import ref from '../../images/Ref.svg';
import Claim from '../../images/Boosts.svg';
import Stat from '../../images/Stats.svg';

const BottomNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getButtonStyles = (path) => ({
    color: 'white',
    width: '60px',
    height: '77px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    fontSize: '12px',
    lineHeight: '20px',
    borderRadius: '12px',
    background: location.pathname === path ? '#15131D  !important' : '#15131D !important',
    borderColor: location.pathname === path ? '#9583FF !important' : 'transparent !important',
    padding: '10px',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: 'Inter',
    border: location.pathname === path ? '1px solid #9583FF !important' : '1px solid #313130 !important',
    gap: '5px',
    justifyContent: 'center',
    transition: 'border-color 0.3s',
    '&:active': {
      backgroundColor: 'transparent',
      border: "1px solid #674EFF",
      color: 'black',
    },
  });

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 15,
        left: 0,
        right: 0,
        background: 'transparent',
        color: 'white',
        borderRadius: '12px 12px 0 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        padding: '10px 0',
      }}
    >
      <Button
        variant="contained"
        onClick={() => navigate('/friends')}
        sx={getButtonStyles('/friends')}
      >
        <span className="capitalize-first">Ref</span>
        <img src={ref} width={40} height={40} />
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/earn')}
        sx={getButtonStyles('/earn')}
      >
        <span className="capitalize-first">Task</span>
        <img src={Task} width={40} height={40} />
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={getButtonStyles('/')}
      >
        <span className="capitalize-first">Tap</span>
        <img src={tap} width={40} height={40} />
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/boost')}
        sx={getButtonStyles('/boost')}
      >
        <span className="capitalize-first">Claim</span>
        <img src={Claim} width={40} height={40} />
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/stats')}
        sx={getButtonStyles('/stats')}
      >
        <span className="capitalize-first">Stats</span>
        <img src={Stat} width={40} height={40} />
      </Button>
    </Box>
  );
};

export default BottomNavBar;
