import { Box, Button, Grid, IconButton, keyframes, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import klinkCoin from "../images/Klink-Coin.svg";
import trophy from "../images/trophy.svg";
import emailIcon from "../images/EmailIcon.svg"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emailVerification } from "../Apis";
import CloseIcon from '@mui/icons-material/Close';
import ThreeSectionLodder from "../components/ThreeSectionLodder";
import DownloadKlinkStep1 from '../images/DownloadKlinkStep1.svg'
import DownArrow from '../images/DownArrow.svg'
import SignUpStep2 from '../images/SignupStep2.svg'
import ConnectEmail from '../images/ConnectEmailStep3.svg'
import accountConneccted from "../images/accountConnected.svg"
import UserRegisterStatus from "../components/UserRegisterStatus"

const Register = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [boxMessage, setBoxMessage] = useState("Email Verification Failed");
  const [accounConnectedModal,setAccounConnectedModal] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  const userData = useSelector((state) => state?.user?.userData);
  const accessToken = useSelector((state) => state?.user?.accessToken)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setDownloadModal(false);
  };

  const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
  const handelSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(emailVerification(email, userId, setLoading, navigate, setIsOpen, setBoxMessage, setIsMessageBoxOpen, accessToken , setAccounConnectedModal));
    } catch (err) {

    }
  }
  const handelStartMission = () => {
    if (userData?.isUserVerified) return;
  }

  const handelEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const linkRef = useRef();

  const handleCopyClick = () => {
    if (linkRef.current) {
      linkRef.current.select();
      document.execCommand('copy');
      alert('Download link copied to clipboard!');
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsMessageBoxOpen(false);
    }, 3000)
  }, [boxMessage])

  const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
  return (
    <div
      style={{
        // background:"#fff",
        width: "100%",
        height: "82vh",
        overflowY: "scroll",
        overflowX: "hidden",
        boxSizing: "border-box",
        paddingBottom: "10px"
      }}
    >
      <UserRegisterStatus />
      <Box
        sx={{
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingTop: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "65px"
        }}
      >
        {/* register info box */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: "25px",
            flexDirection: "column"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "12px",
              flexDirection: "column"
            }}
          >
            <Typography
              component={"h1"}
              sx={{
                fontFamily: "inter",
                fontWeight: 800,
                fontSize: "24px",
                lineHeight: "28.8px",
                color: "#fff",
              }}
            >
              Register and Connect your Klink account to this bot
            </Typography>
            {/* <Typography
          component={"p"}
          sx={{
            fontFamily: "inter",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#fff",
          }}
        >
        How to Connect Klink TG Bot to Your Main Klink Account:
        </Typography> */}
            <Typography variant="h6" gutterBottom sx={{
              color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700,
              fontSize: "14px", lineHeight: "16.94px",
            }}>
              How to Connect Klink TG Bot to Your Main Klink Account:
            </Typography>
            <div style={{ padding: '20px' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={DownloadKlinkStep1} alt="Download Klink" style={{ display: 'block' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700, fontSize: "14px", lineHeight: "16.94px" }}>
                1. Download Klink: <span style={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "16.94px" }}>
                  from the app store (click{' '}
                  “Start Mission” below to be re-directed automatically).
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={DownArrow} alt="Download Klink" style={{ display: 'block', paddingTop: "0px !important" }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={SignUpStep2} alt="Download Klink" style={{ display: 'block' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700, fontSize: "14px", lineHeight: "16.94px" }}>
                Sign Up : <span style={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "16.94px" }}>
                Create your account in minutes.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={DownArrow} alt="Download Klink" style={{ display: 'block' }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={ConnectEmail} alt="Download Klink" style={{ display: 'block', marginBottom: '16px' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700, fontSize: "14px", lineHeight: "16.94px" }}>
                Connect Email :  <span style={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "16.94px" }}>
                Come back to this page and enter your Klink account email to finish task.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: "center",
              padding: "10px",
              background: "#15131D",
              border: " 1px solid #313130",
              width: "100%",
              boxSizing: "border-box",
              borderRadius: "12px",
              gap: "5px"

            }}
          >
            <img src={trophy} alt="Trophy_icon" width={40} height={40} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px"
              }}
            >
              <Typography
                component={"p"}
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "18.2px",
                  color: "#FFFFFF",
                }}
              >Sign up Reward</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px"
                }}
              >
                <img src={klinkCoin} alt="klink_coin" width={16} height={16} />
                <Typography
                  sx={{
                    fontFamily: "inter",
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "15.6px",
                    color: "#fff",
                  }}
                >3,000,000</Typography>
              </Box>
            </Box>
          </Box>


          <button
            style={{
              outline: "none",
              border: "none",
              padding: "10px",
              width: "100%",
              borderRadius: "12px",
              background: "#674EFF",
              fontFamily: "inter",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "23.4px",
              color: "#fff",
              cursor: "pointer"
            }}
            onClick={() => window.open("https://join.klinkfinance.com/17iCBR3RkLb", "_blank")}
          >
            Download Klink
          </button>
        </Box>


        <form

          style={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "start",
            gap: "25px",
            flexDirection: "column"
          }}
          onSubmit={handelSubmit}
        >
          <Typography

            component={"p"}
            sx={{
              fontFamily: "inter",
              fontWeight: 800,
              fontSize: "24px",
              lineHeight: "28.8px",
              color: "#fff",
            }}

          >Enter your Klink email to confirm and connect account</Typography>

          <Box
            sx={{
              background: "#31313080",
              padding: "10px",
              width: "100%",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box"

            }}
          >
            <img src={emailIcon} alt="email_icon" />
            <input type="email" required style={{
              width: "90%",
              fontFamily: "inter",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "23.4px",
              color: "#fff",
              background: "transparent",
              outline: "none",
              border: "none",
              backgroundColor: "transparent"

            }}
              placeholder="Enter email (We'll verify this)"
              name="KlinkEmail"
              onChange={handelEmailChange}

            />
          </Box>
          <button
            style={{
              outline: "none",
              border: "none",
              padding: "16px",
              width: "100%",
              borderRadius: "12px",
              background: "#674EFF",
              fontFamily: "inter",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "23.4px",
              color: "#fff",
              cursor: "pointer"
            }}
            type="submit"
          >

            {!loading ? "Submit" : "Loading"}
          </button>
        </form>
      </Box>
      {isOpen && <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          background: '#1B1529FA',
          height: '493px',
          zIndex: 1000000,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '25px',
        }}
      >
        <IconButton

          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: '#fff',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center"

          }}
          onClick={() => setIsOpen(false)}
        >
          <CloseIcon />
        </IconButton>

        <Box>

          <ThreeSectionLodder />
        </Box>

      </Box>}

      {isMessageBoxOpen &&

        <Box
          sx={{
            width: "300px",
            height: "50px",
            position: "absolute",
            background: "#674EFF",
            top: "10px",
            left: "35px",
            right: 0,
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: " 1px solid #313130",
            animation: `${fadeIn} 0.5s  forwards`
          }}

        >
          <Typography
            sx={{
              fontFamily: "inter",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "28.8px",
              color: "#fff",
              textAlign: "center",


            }}
          >
            {boxMessage}
          </Typography>
        </Box>}

      {/* {downloadModal && (
        <Box
          style={{
            display: downloadModal ? '' : 'none',
            textAlign: 'center',
            backgroundColor: '#1B1529FA',
            padding: '20px',
            borderRadius: '10px',
            maxWidth: '600px',
            margin: '20px auto',
            position: "absolute",
            bottom: "0",
            zIndex: 10000000,
          }}
        >
       
          <IconButton
            onClick={handleCloseModal}  // Replace with your close modal function
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>

          
          <Typography variant="h5" style={{ color: 'white', fontFamily: 'Inter', fontWeight: 800 }}>
            Download Klink App  <span style={{ display: 'inline-block' }}>Get Klink Today!</span>
          </Typography>
          <div
            style={{
              width: 250,
              height: 250,
              margin: '20px auto',
              background: '#FFFFFF',
              borderRadius: 35,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              src={klinkQR}
              width={200}
              height={200}
              style={{ borderRadius: 35 }}
              alt="Klink QR Code"
            />
          </div>
          <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", gap: "5px" }}>
            <Typography color={'#FFFFFF'} fontSize={'24px'} fontFamily={'Inter'} fontWeight={700}>
              Tap To Download
            </Typography>
            <Typography>
              <a href={"https://bit.ly/klinkwebsite"} style={{ color: 'white', textDecoration: 'none' }}>
                <img src={AppStore} alt="Apple Store" width={34} height={34} style={{ marginRight: "10px" }} />
              </a>
              <a href={"https://bit.ly/klinkwebsite"} style={{ color: 'white', textDecoration: 'none' }}>
                <img src={playstore} alt="Google Play Store" width={34} height={34} />
              </a>
            </Typography>
          </Box>

          <Box style={{ justifyContent: 'center' }}>
            <TextField
              component={"a"}
              src={"https://bit.ly/klinkwebsite"}
              inputRef={linkRef}
              value="https://bit.ly/klinkwebsite"
              InputProps={{
                readOnly: true,
                sx: {
                  color: 'white',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopyClick} color="primary">
                      <img src={copy} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ margin: '8px', width: '80%' }}
            />
          </Box>
        </Box>
      )} */}
       {accounConnectedModal &&  <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        background: "#1B1529FA",
        height: "393px",
        zIndex: 1000000,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "15px",
        animation: `${slideUp} 200ms ease-out forwards`,
        borderRadius: "25px",
        boxSizing:"border-box"
      }}
    >
      <>
        <IconButton
     
          sx={{
            position: "absolute",
            top: "12px",
            right: "12px",
            color: "#fff",
          }}

          onClick={ () => setAccounConnectedModal(false)}
        >
          <CloseIcon width={16} height={16} />
        </IconButton>
       <img src={ accountConneccted  } alt="icon" width={160} height={160} /> 
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            
            height:"101px"
          }}
        >
          <Typography
            component="p"
            sx={{
              fontFamily: "Inter",
              fontWeight: 800,
              fontSize: "32px",
              lineHeight: "38.4px",
              color: "#fff",
              letterSpacing:"-1px"
            }}
          >
        Account Connected
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16.94px",
              color: "#fff",
              marginTop:"12px",
              textTransform: 'none !important'
            }}
          >
            Click below to be redirected to your Klink app
          </Typography>
        </Box>

        <Button
          style={{
            width: "350px",
            background: "#674EFF",
            height:"55px",
            outline: "None",
            border: "None",
            borderRadius: "12px",
            marginX:'auto',
            textTransform: 'none'
          }}
          onClick={ ()=> navigate("/earn")}
          // href="https://join.klinkfinance.com/17iCBR3RkLb"
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 873,
              fontSize: "18px",
              lineHeight: "23.4px",
              color: "#fff",
              textAlign:"center"
            }}
          >
           Done 
          </Typography>
        </Button>
      </>
      </Box>}
    </div>
  );
};
export default Register