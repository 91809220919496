import { Box, Button, ToggleButton, ToggleButtonGroup, keyframes, styled } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import StatsSvg from "../images/Stats.svg";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setUserStats } from "../store/slices/userSlice";
import Coin_ from '../images/Klink-Coin.svg'
import { getUserStats } from "../Apis";
import tap from '../images/Tap.svg'
import moment from 'moment'
import klinkProfile from '../images/klinkProfile.svg'
import userperref from '../images/@Icon.svg'
import ThreeSectionLodder from "../components/ThreeSectionLodder";
import { FunctionCommas } from '../utils/commasFun'
import FriendReferalIMG from '../images/FriendReferalIMG.svg'
import NumberofTonTickets from '../images/NumberofTonTickets.svg'
import Popup from "../components/Popup";
import Copy from '../images/copy.svg'
const Stats = () => {

  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  const userReferalCount = useSelector((state) => state.user.userData.referrals)
  const currentPoint = useSelector((state) => state?.point?.currentPoint);
  const totalKlinkers = useSelector((state) => state.user.totalKlinkers)
  const totalKlinkersPoints = useSelector((state) => state.user.totalKlinkersPoints)
  const dailyActiveKlinkers = useSelector((state) => state.user.dailyActiveKlinkers)
  const activeKlinkers = useSelector((state) => state.user.activeKlinkers)
  const totalTouches = useSelector((state) => state.user.totalTouches)
  const klinkLeaderboard = useSelector((state) => state.user.klinkLeaderboard)
  const referralCount = useSelector((state) => state.user.referralCount)
  const lifeTimePoints = useSelector((state) => state.user.lifeTimePoints)
  const refralsInfo = useSelector((state) => state.user.refralsInfo)
  const numberofTonTickets = useSelector((state) => state.user.userData.numberOfTickets)
  const totalReferralEarning = useSelector((state) => state.user.totalReferralEarning)
  const [initialRender, setInitialRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const userData = useSelector((state) => state?.user?.userData);


  console.log("userReferalCount", userReferalCount);


  // useEffect(() => {
  //   // dispatch(getUserReferal(userId));
  //   dispatch(getUserStats(userId,setLoading,accessToken));

  // }, [dispatch, userId]);
  // console.log("klinkLeaderboard", klinkLeaderboard);


  const FadeContainer = styled(Box)`
  opacity: ${initialRender ? 0 : 1};
  transition: opacity 1s ease-in-out;
`;

  const [value, setValue] = useState('Community');

  const changeValue = (_, v) => {
    if (v !== null) setValue(v);
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialRender(false);
    }, 500); // Adjust the timeout to match the fade-in duration
    return () => clearTimeout(timer);
  }, []);

  const fromBottom = keyframes`
  0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;
const handleInviteClick = () => {
  const referralLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userId}🚀`;
  const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(
    "Join me on Klink's TG Bot and start earning crypto! Use the link to get a 5000 Klink points welcome bonus  \n\n" 
  )}`;
  window.open(telegramShareUrl, "_blank");
};


const handleCopyClick = () => {
  const referralLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userId}`;
  navigator.clipboard.writeText(referralLink).then(
    () => {
      alert("Referral link copied to clipboard!");
    },
    (err) => {
      console.error("Could not copy text: ", err);
    }
  );
};

  const memoizedInviteClick = useCallback(() => {
  handleInviteClick();
}, [handleInviteClick]);

const memoizedCopyClick = useCallback(() => {
  handleCopyClick();
}, [handleCopyClick]);



  const ContentContainer = initialRender ? Box : Box;
  return (
    <>

      {loading ? (
        <Box sx={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
          <ThreeSectionLodder />
        </Box>
      ) : (
        <Box >
          <Box m={1} sx={{ display: 'flex', position:"relative", justifyContent: 'center', flexDirection:"column", alignItems: 'center', mt: 5, }}>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={value}
              onChange={changeValue}
              sx={{
                '& .MuiToggleButton-root': {
                  width: '174px',
                  height: '31px',
                  background: "#313130",
                  borderRadius: "8px",
                  color: "white !important",
                },
                '& .Mui-selected': {
                  backgroundColor: '#674EFF !important',
                  color: "white !important",
                  borderRadius: "8px"
                },
              }}
            >
              <ToggleButton value={'Myactivity'} sx={{ fontWeight: 700, fontFamily: "Inter", fontSize: "14px", color: "#FFFFFF" }}>My Activity</ToggleButton>
              <ToggleButton value={'Community'} sx={{ fontWeight: 700, fontFamily: "Inter", fontSize: "14px", color: "#FFFFFF" }}>Community</ToggleButton>
            </ToggleButtonGroup>

            {value === 'Myactivity' &&  <Popup message={!userData?.isUserVerified ? "Klink account is not connected":"Klink account is connected"} status={  userData?.isUserVerified ?  "SUCCESS" : "REJECT"} /> }
          </Box>

          {value === 'Community' && (
            <ContentContainer style={{ height: "70vh", padding: "24px", overflowY: "auto" }}>
              <Box
                sx={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "14px", // Add gap here
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* <Typography
          variant="body1"
          component="p"
          sx={{
            margin: 0,
            color: "white",
            fontWeight: 700,
            fontSize: "1.25rem",
            fontFamily: "Lexend",
          }}
        >
          Total Share Balance
        </Typography> */}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "10px",
                    gap: "12px"
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      lineHeight: "16.94px"
                    }}
                  >
                    My Points
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent={'center'}>
                    <img src={Coin_} alt="klinkCoin.svg" style={{ marginRight: "13.5px" }} width={36} height={40} />
                    <Typography
                      component="p"
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: 700,
                        fontSize: "42px",
                        lineHeight: "50.4px",
                        fontFamily: "Inter",
                        height: "50px",
                        textAlign: "center"
                      }}
                    >
                      {FunctionCommas(currentPoint)}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      border: "1px solid #FFFFFF",
                      opacity: "10%",
                      width: "80vw",
                      marginTop: "23px"
                    }}
                  ></Typography>

                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "10px",
                    paddingX: "15px",
                    paddingY: "10px",
                    minWidth: "200px",
                    width: "100%",
                    marginTop: "15px",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Inter",
                    }}
                  >
                    Community Points
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      fontSize: "24px",
                      lineHeight: "24.5px",
                      fontFamily: "Inter",
                      marginTop: "10px"
                    }}
                  >

                    {FunctionCommas(totalKlinkersPoints)}

                  </Typography>
                </Box>

                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "10px",
                    paddingX: "15px",
                    paddingY: "10px",
                    minWidth: "200px",
                    width: "100%",
                    marginTop: "15px",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Inter",
                    }}
                  >
                    Total Touches
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "24.5px",
                      fontFamily: "Inter",
                    }}
                  >

                    {FunctionCommas(totalTouches)}

                  </Typography>
                </Box> */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "10px",
                    paddingX: "15px",
                    paddingY: "10px",
                    minWidth: "200px",
                    width: "100%",
                    // marginTop: "15px",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#F4FDFF",
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Inter",
                    }}
                  >
                    Total Klinkers
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      fontSize: "24px",
                      lineHeight: "25px",
                      fontFamily: "Inter",
                      marginTop: "10px"
                    }}
                  >
                    {FunctionCommas(totalKlinkers)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "10px",
                    paddingX: "15px",
                    paddingY: "10px",
                    minWidth: "200px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Inter",
                    }}
                  >
                    Daily Klinkers
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      fontSize: "24px",
                      lineHeight: "25px",
                      fontFamily: "Inter",
                      marginTop: "10px"
                    }}
                  >
                    {FunctionCommas(dailyActiveKlinkers)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "10px",
                    paddingX: "15px",
                    paddingY: "10px",
                    minWidth: "200px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Inter",
                    }}
                  >
                    Currently Online
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      fontSize: "24px",
                      lineHeight: "25px",
                      fontFamily: "Inter",
                      marginTop: "10px"
                    }}
                  >
                    {FunctionCommas(activeKlinkers)}
                  </Typography>
                </Box>
                <Box sx={{ border: "1px solid #313130", width: "100%", height: "100%", borderRadius: "12px", marginTop: "45px" }}>
                  <Box sx={{ marginLeft: "16px", marginTop: "16px", marginBottom: "10px" }}>
                    <Typography sx={{ fontFamily: "Inter", fontWeight: 873, fontSize: "18px", lineHeight: "23.4px", letterSpacing: "-1%", color: "#FFFFFF", }}>
                      Community Leaderboard
                    </Typography>
                    {klinkLeaderboard?.length > 0 ? (
                      klinkLeaderboard?.map((User, index) => (
                        <Box
                          key={index}
                          color="white"
                          sx={{
                            textAlign: 'left',
                            paddingTop: '12px',
                            display: 'flex',
                            flexDirection: 'column', // Change to column to stack items vertically
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={User.profileImage ? User.profileImage : klinkProfile}
                              width={40}
                              height={40}
                              style={{
                                marginRight: '10px',
                                padding: '5px',
                                borderRadius: '40px',
                              }}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                flexGrow: 1,
                              }}
                            >
                              <Typography
                                style={{
                                  color: '#FFFFFF',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  lineHeight: '20.8px',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {User.username}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'end',
                                flexGrow: 1,
                                marginRight: '16px',
                              }}
                            >
                              <Typography
                                style={{
                                  color: '#FFFFFF',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  lineHeight: '20.8px',
                                  fontFamily: 'Inter',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={Coin_}
                                  alt="klinkCoin.svg"
                                  style={{ marginRight: "5px" }}
                                  width={16}
                                  height={16}
                                />
                                {FunctionCommas(User.points)}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            sx={{
                              border: "1px solid #FFFFFF",
                              opacity: "10%",
                              width: "80vw",
                              marginTop: "12px", // Adjust the margin as needed
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          ></Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography color={'white'}>No Kinkers</Typography>
                    )}
                  </Box>

                </Box>
              </Box>

            </ContentContainer>
          )}
          {value === 'Myactivity' && (
            <ContentContainer style={{ height: "65vh", padding: "24px" }}>
              <Box
                sx={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "14px",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "10px",
                    paddingX: "15px",
                    paddingY: "10px",
                    minWidth: "200px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textAlign: "center"
                    }}
                  >
                    Total lifetime points
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px"
                    }}
                  >
                    <img src={Coin_} style={{ marginRight: "10px" }} width={38} height={38} alt="Klink Coin" />
                    <Typography
                      component="p"
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: 700,
                        fontSize: "42px",
                        lineHeight: "24.5px",
                        fontFamily: "Inter",
                      }}
                    >
                      {lifeTimePoints}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    border: "1px solid #FFFFFF",
                    opacity: "10%",
                    width: "80vw",
                    marginTop: "23px",
                  }}
                ></Typography>
              </Box>
              <Box sx={{ marginTop: "28px" }}>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "0px" }}>
                  <Typography color={'#FFFFFF'} textAlign={'center'} fontFamily={'Inter'} fontSize={'12px'} fontWeight={400} lineHeight={'14.52px'}>
                    Friends
                  </Typography>
                  <Typography color={'#FFFFFF'} textAlign={'center'} fontFamily={'Inter'} fontSize={'12px'} fontWeight={400} lineHeight={'14.52px'}>
                    Referred
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: "7px", marginTop: "10px" }}>
                  <img src={FriendReferalIMG} width={26} height={28} />
                  <Typography sx={{ fontFamily: "Inter", fontSize: "24px", fontWeight: 800, color: "#FFFFFF" }}>
                    {userReferalCount}
                  </Typography>


                </Box>

                <Box sx={{ marginTop: "20px" }} >
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Typography color={'#FFFFFF'} textAlign={'center'} fontFamily={'Inter'} fontWeight={400} fontSize={'12px'} lineHeight={'14.52px'}>
                      Points earned from
                    </Typography>
                     <Typography color={'#FFFFFF'} textAlign={'center'} fontFamily={'Inter'} fontWeight={400} fontSize={'12px'} lineHeight={'14.52px'}>
                    sharing with friends
                  </Typography>

                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px", gap: "10px" }}>
                  <img
                    src={Coin_}
                    alt="klinkCoin.svg"
                    // style={{ marginRight: "13.5px" }}
                    width={26}
                    height={26}
                  />
                  <Typography
                    color="#FFFFFF"
                    textAlign="center"
                    fontFamily="Inter"
                    fontWeight={700}
                    fontSize="18px"
                  >
                    {FunctionCommas(totalReferralEarning)}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    border: "1px solid #FFFFFF",
                    opacity: "10%",
                    width: "80vw",
                    marginTop: "23px",
                    display: "block",
                    margin: "auto",
                    marginTop: "24px !important"
                  }}
                ></Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "28px" }}>
                <Typography color={'#FFFFFF'} fontFamily={'Inter'} fontWeight={400} fontSize={'12px'} lineHeight={'14.52px'} textAlign={'center'}>
                  TON Prize Pool tickets claimed
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "7px", marginTop: "10px" }}>
                <img src={NumberofTonTickets} width={33} height={22} />
                <Typography color={'#FFFFFF'} fontSize={'24px'} fontFamily={'Inter'} fontWeight={800}>
                  {numberofTonTickets}
                </Typography>
              </Box>
            </Box>
            </ContentContainer>
          )}

       <Box
        sx={{
          position: "fixed",
          bottom: "15%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: "10px",
          boxSizing: "border-box",
          zIndex: 1000, // Ensure buttons are above other content
          animation: `${fromBottom} 1s cubic-bezier(0.25, 0.8, 0.25, 1)`
        }}
      >
        {userData?.isUserVerified && value=="Myactivity" &&   <Button
          sx={{
            bgcolor: "#674EFF",
            width: "95%",
            height: "55px",
            color: "#FFFFFF",
            borderRadius: "12px",
            "&:hover": {
              bgcolor: "#5A42E6", // Adjust hover color if needed
            },
          }}
          onClick={memoizedInviteClick}
        >
        
          <Typography
            
            sx={{
                fontSize:"18px",
                fontWeight:700,
                fontFamily:"Inter",
                color:"#FFFFFF"
            }}
            >
          Invite a friend
            </Typography>
        </Button>}
        {!userData?.isUserVerified && value=="Myactivity" &&   <Button
          sx={{
            bgcolor: "#674EFF",
            width: "95%",
            height: "55px",
            color: "#FFFFFF",
            borderRadius: "12px",
            "&:hover": {
              bgcolor: "#5A42E6", // Adjust hover color if needed
            },
          }}
          href="https://join.klinkfinance.com/17iCBR3RkLb"
        >
        
          <Typography
            
            sx={{
                fontSize:"18px",
                fontWeight:700,
                fontFamily:"Inter",
                color:"#FFFFFF"
            }}
            >
         Connect Klink account
            </Typography>
        </Button>}
        
      </Box>
       </Box >
      )}
    </>
  );
};

export default Stats;
