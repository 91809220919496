import React from 'react'
import userIcon from '../images/userIcon.svg'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

const UserRegisterStatus = () => {

    const userData = useSelector((state) => state?.user.userData)
  return (
    <Box
    sx={{
      width: '36px',
      height: "36px",
      position: "absolute",
      backgroundColor: "#443961E5",
      top: 16,
      right: "11px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      borderRadius: "50px",
      backdropFilter: 'blur(50px)',
      padding: "2px"
    }}
  >
    <img src={userIcon} alt="user_icon" width={28} height={36} style={{ position: "fixed", zIndex: 100, objectFit:"contain" }} />
    <Box
      sx={{
        position: "absolute",
        width: "10px",
        height: "10px",
        background: `${userData?.isUserVerified ? "#00e600" : "#FB4667"}`,
        top: 0,
        borderRadius: "50px",
        left: "2px",
      }}
    >
    </Box>
  </Box>
  )
}

export default UserRegisterStatus
