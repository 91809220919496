import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPoint: 0,
  userPoint: 0,
};

const pointSlice = createSlice({
  name: "point",
  initialState,
  reducers: {
    setCurrentTokenCount: (state, actions) => {
      if (actions.payload > 1) {
        state.currentPoint = state.currentPoint + actions.payload;
      } else {
        state.currentPoint = state.currentPoint + 1;
      }
    },
    setUserPoint: (state, actions) => {

          state.userPoint = actions.payload;

      },
      setCurrentPoint: (state,action)=>{
        state.currentPoint = action.payload;
      },
      reduceUserPoints : (state,action) => {
        state.userPoint = state.userPoint - action.payload;
      },
      reduceCurrentPoint : (state,action) => {
        state.currentPoint = state.currentPoint - action.payload;
      },
      IncreaseCurrentPoint : (state,action) => {
        state.currentPoint = state.currentPoint  + action.payload;
      },
      IncreaseUserPoints : (state,action) => {
        state.userPoint = state.userPoint + action.payload;
      }
  },
});

export const { setCurrentTokenCount,setUserPoint, setCurrentPoint,reduceUserPoints, reduceCurrentPoint, IncreaseCurrentPoint, IncreaseUserPoints } = pointSlice.actions;

export default pointSlice.reducer;